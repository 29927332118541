import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import queryString from "query-string";
import "./quotation.module.css";
import ProgressBar from "react-progressbar-on-scroll";

// API CALL IMPORT
import { getContent } from "../constants/ApiCall";
import Header from "../Components/common/header";
const BUCKET_URL = "https://all-user-version-4.s3.us-east-1.amazonaws.com/";
function SharedFile(props) {
  // CONSTANTS
  const { uniqueLinkId } = props.match.params;

  // STATE
  const [isActive, setLoader] = useState(true);
  const [showLoaderSpinner, setShowLoaderSpinner] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState("Loading Page");
  const [pageContent, setPageContent] = useState(null);
  const [pdfArr, setPdfArr] = useState();
  const [imgArr, setImgArr] = useState();
  // METHODS
  useEffect(() => {
    loadContent();
  }, []);
  useEffect(() => {
    if (pageContent && pageContent.content.details.location) {
      setTimeout(() => {
        window.initMap(
          pageContent.content.details.location.latitude,
          pageContent.content.details.location.longitude
        );
      }, 2000);
    }
  }, [pageContent]);
  const loadContent = async () => {
    try {
      let queryParams = queryString.parse(props.location.search);
      const response = await getContent(uniqueLinkId, queryParams.u || "");
      console.log("page", response);
      if (response.data.content.files) {
        const files = response.data.content.files;
        const pdfs = [];
        const images = [];
        files.map((_item) => {
          if (
            _item &&
            ["jpg", "png", "gif", "jpeg"].includes(
              _item.fileName
                .split(".")
                [_item.fileName.split(".").length - 1].toLowerCase()
            )
          ) {
            images.push(_item.filePath);
          } else if (
            _item &&
            ["pdf"].includes(
              _item.fileName
                .split(".")
                [_item.fileName.split(".").length - 1].toLowerCase()
            )
          ) {
            pdfs.push(_item.filePath);
          }
        });
        setPdfArr(pdfs);
        setImgArr(images);
      }
      setPageContent(response.data);
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  // HOOKS
  const popYouTubeId = (youTubeUrl) => {
    let youTubeId;
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = youTubeUrl.match(regExp);
    if (match && match[2].length == 11) {
      youTubeId = match[2];
    } else {
      youTubeId = "no video found";
    }
    console.log(`https://www.youtube.com/embed/${youTubeId}`);
    return `https://www.youtube.com/embed/${youTubeId}`;
  };
  return (
    <LoadingOverlay
      active={isActive}
      spinner={showLoaderSpinner}
      text={loaderMessage}
    >
      <div
        className="container container-div"
        style={{ minHeight: "100vh", background: "#FFF" }}
      >
        <Header
          pageName={
            pageContent?.content.details.title &&
            pageContent?.content.details.title
          }
          organisation={pageContent?.user.organization.name}
          lead={pageContent?.lead ? pageContent?.lead : ""}
        />
        {/* <div className="header-bottom"></div> */}

        {!isActive && (
          <div className="row">
            <div className="template">
              {/*--button-*/}
              {imgArr.map((file) => {
                return (
                  <div className="second-img">
                    <img
                      className="img-fluid cover-bg-darkened"
                      style={{ width: "100%" }}
                      src={BUCKET_URL + file}
                      alt="img"
                    />
                  </div>
                );
              })}
              {pdfArr.map((file) => {
                return (
                  <div className="second-img">
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={[{ uri: BUCKET_URL + file }]}
                    />
                  </div>
                );
              })}
              <div className="image-content">
                <p>{pageContent?.content.details.description}</p>
              </div>
              <div className="button-area">
                <p>Want to find out to more?</p>
                <p>
                  <a href={`https://wa.me/${pageContent?.user?.phone}`}>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="contact-text align-items-center">
                        <h4>Contact Me</h4>
                        <img
                          className="img-fluid cover-bg-darkened icon-small"
                          style={{ marginBottom: 0 }}
                          src={"../assets/images/whatsapp.svg"}
                          alt={"imgs"}
                        />
                      </div>
                    </div>
                  </a>
                </p>
              </div>
              {/*--secont-img-*/}
              {pageContent?.content.details.videoUrl &&
                pageContent?.content.details.videoUrl.split(",").map((item) => {
                  // try {
                  return (
                    <div className="video-img" style={{ position: "relative" }}>
                      <iframe
                        // width="100%"
                        // height="100%"
                        src={popYouTubeId(item)}
                        style={{
                          borderRadius: "5px",
                          height: "100%",
                        }}
                        title="my-video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      />
                    </div>
                  );
                  // } catch (err) {
                  //   console.log("url", popYouTubeId(item), err);
                  //   return null;
                  // }
                })}
              {/*--secont-img-*/}
              {pageContent?.content.details.location && (
                <div className="map-area">
                  <div id="map"></div>
                </div>
              )}
              {/*--secont-img-*/}
              <div className="footer-area" style={{ background: "#EBEFF4" }}>
                <p
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Shared by
                </p>
                <img
                  src={
                    pageContent?.user?.profile
                      ? `${BUCKET_URL}${pageContent?.user?.profile.filePath}`
                      : window.location.origin + "/assets/images/6.png"
                  }
                  className="circle-image"
                />
                <div className="content-details">
                  <h5>
                    {pageContent?.user?.firstName} {pageContent?.user?.lastName}
                  </h5>

                  <p>{pageContent?.user.organization.name}</p>
                  <span className="phone-no">{pageContent?.user?.phone}</span>
                  <div className="icon-area">
                    <ul>
                      <li>
                        <a href={`tel:${pageContent?.user?.phone}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"/assets/images/Phone.png"}
                            alt={"imgs"}
                          />
                          <br />
                          <span
                            id="sp"
                            style={{
                              color: "#0E9CFF",
                            }}
                          >
                            Call
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href={`sms://${pageContent?.user?.phone}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"/assets/images/sms.png"}
                            alt={"imgs"}
                          />
                          <br />
                          <span
                            id="sp"
                            style={{
                              color: "#0b2357",
                            }}
                          >
                            SMS
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://wa.me/${
                            pageContent?.user?.phone.split("+")[1]
                          }`}
                        >
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"/assets/images/wtsapp.png"}
                            alt={"imgs"}
                          />
                          <br />
                          <span
                            id="sp"
                            style={{
                              color: "#0ca30b",
                            }}
                          >
                            Whatsapp
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href={`https://wa.me/${pageContent?.user?.phone}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small save-img"
                            src={"../assets/images/save.png"}
                            alt={"imgs"}
                          />
                          <br />
                          <span
                            id="sp"
                            style={{
                              color: "#68042e",
                            }}
                          >
                            Save
                          </span>
                        </a>
                      </li>
                    </ul>
                    <p
                      style={{
                        color: "#389cdf",
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      Powered By 3Sigma
                    </p>
                  </div>
                  {/*-icon-area-*/}
                </div>
                {/*--content-details--*/}
              </div>
              {/*---foooter-area-*/}
            </div>
            {/*-column-- t*/}
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
}

export default SharedFile;
