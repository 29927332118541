import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
// MATERIAL COMP IMPORT
import { makeStyles } from "@material-ui/core/styles";
import Integration from "../Components/Integration Components/Integration";
import { getGoogleTokens } from "../constants/ApiCall";

// UTILS CALL
import { API_URL } from "../constants/utils";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleClientService } from "../constants/Google_client_service";

import Header from "../Components/Integration Components/Header";
import Button from "../Components/Integration Components/Button";
import Logo from "../images/google_calendar.png";
import moment from "moment";

const axios = require("axios");

function GoogleCalenders(props) {
  const [open, setOpen] = useState(true);
  const [integration, setIntegration] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");

  const googleAuth = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      const tokens = await axios.post(`${GoogleClientService.web.token_uri}`, {
        code: codeResponse.code,
        grant_type: "authorization_code",
        client_id: `${GoogleClientService.web.client_id}`,
        client_secret: `${GoogleClientService.web.client_secret}`,
        redirect_uri: `${GoogleClientService.web.redirect_uris[0]}`,
      });
      getGoogleTokens({
        tokens: {
          access_token: `${tokens.data.access_token}`,
          scope: `${tokens.data.scope}`,
          token_type: `${tokens.data.token_type}`,
          id_token: `${tokens.data.id_token}`,
          expiry_date: tokens.data.expires_in,
          refresh_token: `${tokens.data.refresh_token}`,
        },
      });
    },
    scope: "https://www.googleapis.com/auth/calendar",
    onError: (errorResponse) => console.log(errorResponse),
  });

  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 13px 0 17px",
      "& .integration_type": {
        gap: "12px",
      },
      "& button": {
        width: "330px",
        position: "relative",
        marginBottom: "50px !important",
        marginTop: "70px !important",
        display: "flex",
        alignItems: "center",
        paddingLeft: "33px",
        gap: "9px"
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      margin: "0",
    },
    iframe: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Google Calender"}
            logo={
              <img
                src={Logo}
                alt="Google Calender"
                width="40"
                height="40"
                style={{ borderRadius: "50%" }}
              />
            }
          />
          <div className={classes.text}>
            You can sync your daily task to Google calender. We will
            automatically create calender entries for all task you create in
            3sigma CRM
          </div>
          <div className={classes.text + " mt-4 ms-3 mb-4 pb-1"}>
            How does Google mail integration work?
          </div>
          <div className={classes.iframe + " iframe_container"}>
            <iframe
              width="300"
              height="150"
              frameBorder="0"
              title="youtube"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
            ></iframe>
          </div>
          {integration ? (
            <Button
              onClick={() => alert("disconnect button clicked")}
            >
              Disconnect
            </Button>
          ) : (
            <Button
              onClick={googleAuth}
            >
              Connect
            </Button>
          )}
        </div>
      </LoadingOverlay>
    </BottomSheet>
  );
}
export default GoogleCalenders;
