import Request from "./request";
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const LOCAL_API_URL = process.env.REACT_APP_LOCAL_API_URL;
const DEV_API_URL = process.env.REACT_APP_DEV_API_URL;
const PROD_API_URL = process.env.REACT_APP_PROD_API_URL;
var API_URL = LOCAL_API_URL;

if (ENVIRONMENT === "prod") {
  API_URL = PROD_API_URL;
} else if (ENVIRONMENT === "dev") {
  API_URL = DEV_API_URL;
}

export const getUser = async (token) => {
  const response = await Request.get(`user/profile`, {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data;
};

export const getQuotation = async (token) => {
  const url = new URL(window.location.href);
  const id = url.pathname.split("/")[2];
  const response = await Request.get(`quotation/${id}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data;
};
export const getIntegration = async (integrationKey, token) => {
  const response = await Request.get(
    `integration/user-integration/${integrationKey}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const createUserIntegration = async (integrationKey, details, token) => {
  console.log(
    integrationKey,
    details,
    token,
    "integrationKey, details, token",
    "API CALL"
  );
  const response = await Request.post(
    `${API_URL}integration/user-integration`,
    {
      integrationKey,
      details,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log(response, "response");
  return response.data;
};
export const uploadFiles = async (params) => {
  const url = new URL(window.location.href);
  const parameterValue = url.searchParams.get("token");
  const headers = {
    headers: {
      Authorization: `${parameterValue}`,
    },
  };

  const response = await Request.post(`/utility/file-upload`, params, headers);
  return response.data;
};
export const importFacebookPastLeads = async (time, token) => {
  const response = await Request.post(
    `lead/facebook-past`,
    { days: time },
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
  return response.data;
};
export const getDigitalCard = async (token) => {
  const response = await Request.get(`digital-card`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const disconnectIntegration = async (integrationId, token) => {
  const response = await Request.put(
    `integration/user-integration/${integrationId}/disconnect`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getUserIntegration = async (token) => {
  const response = await Request.get(`integration/user-integration`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const updateUserIntegration = async (id, objs, token) => {
  const response = await Request.put(
    `integration/user-integration/${id}`,
    objs,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getLeadList = async (params, token) => {
  const response = await Request.get(`lead-list`, {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getGoogleTokens = async (params, token) => {
  const response = await Request.post(`user/get-google-tokens`, params, {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data;
};

export const getContent = async (uniqueLinkId, u, token) => {
  const response = await Request.get(
    `content/get-by-unique-link?uniqueLinkId=${uniqueLinkId}&userId=${u}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
  return response.data;
};

//http://43.204.174.121:5000/api/v1/content/get-by-unique-link?uniqueLinkId=lgdi846l6419b2278bf444297486674ab739ae2d90e5
