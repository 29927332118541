import React, { useState, useEffect } from "react";
import Doc from "../Components/PdfDocService";
import PdfContainer from "../Components/PdfContainer";
import axios from "axios";
import html2pdf from "html2pdf.js";
import "./quotation.module.css";
import "./custom.module.css";
import { useMediaQuery } from "react-responsive";

const borderBottom = {
  borderBottom: "1px solid #eee",
};

const Invoice = () => {
  const generatePdf = (html) => Doc.createPdf(html);
  const exportPDF = (html) => Doc.exportPDF(html, invoiceData, "invoice", 0.6);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 576px)" });

  const [bucketUrl, setBucketUrl] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [vcfPath, setVcfPath] = useState("");

  useEffect(() => {
    fetchInvoiceData();

    async function fetchInvoiceData() {
      const url = new URL(window.location.href);
      const id = url.pathname.split("/")[2];
      const parameterValue = url.searchParams.get("token");

      var response = await axios.get(
        `https://mapi2.3sigmacrm.com/api/v1/invoice/${id}`,
        {
          headers: {
            Authorization: `Bearer ${parameterValue}`,
            // "token-check-disable":true
            // test
          },
        }
      );
      setInvoiceData(response.data.data);
      if (response.data.data.products[0].currency === "₹") {
        setCurrencySymbol("Rs ");
      } else {
        setCurrencySymbol(response.data.data.products[0].currency);
      }
    }
  }, []);

  function decimalToEnglish(decimalNumber) {
    // Separate the integer and decimal parts
    let [integerPart, decimalPart] = decimalNumber.toString().split(".");

    // Convert integer part to English words with commas
    let integerPartWords = numberToEnglishSentence(parseInt(integerPart));

    // Convert decimal part to English words
    let decimalPartWords = "";
    if (decimalPart !== undefined) {
      decimalPartWords = numberToEnglishSentence(parseInt(decimalPart));
    }

    // Concatenate the integer and decimal parts
    let result = integerPartWords;
    if (decimalPartWords !== "") {
      result += " decimal " + decimalPartWords;
    }

    return result;
  }

  // Function to convert a number to its English word representation
  function numberToEnglishSentence(number) {
    const numbersToWordsLessThanTwenty = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const multiplesOfTen = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    function convertToWords(num) {
      if (num < 20) {
        return numbersToWordsLessThanTwenty[num];
      } else if (num < 100) {
        return (
          multiplesOfTen[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + convertToWords(num % 10) : "")
        );
      } else if (num < 1000) {
        return (
          numbersToWordsLessThanTwenty[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " " + convertToWords(num % 100) : "")
        );
      } else if (num < 1000000) {
        return (
          convertToWords(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? ", " + convertToWords(num % 1000) : "")
        );
      } else if (num < 1000000000) {
        return (
          convertToWords(Math.floor(num / 1000000)) +
          " Million" +
          (num % 1000000 !== 0 ? ", " + convertToWords(num % 1000000) : "")
        );
      } else {
        return (
          convertToWords(Math.floor(num / 1000000000)) +
          " Billion" +
          (num % 1000000000 !== 0
            ? ", " + convertToWords(num % 1000000000)
            : "")
        );
      }
    }

    if (number === 0) {
      return "Zero";
    } else {
      return convertToWords(number);
    }
  }

  // asdasdsad
  function createDate(date) {
    if (date) {
      let quotDate = new Date(date);
      return (
        quotDate.getDate() +
        "/" +
        (quotDate.getMonth() > 9
          ? quotDate.getMonth()
          : "0" + quotDate.getMonth()) +
        "/" +
        quotDate.getFullYear()
      );
    } else {
      return;
    }
  }
  let convertToPDF = () => {
    // Get the element to convert
    const element = document.getElementById("convertEd");

    // Set up options for the PDF conversion (optional)
    const options = {
      margin: 5,
      filename: "Invoice.pdf",
      image: { type: "png", quality: 0.99 },
      html2canvas: { scale: 2, useCORS: true },
      // jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      jsPDF: { unit: "in", format: "a4", orientation: "p" },
    };

    // Call the html2pdf function
    html2pdf().from(element).set(options).save();
  };

  function formatWithCommas(decimalValue) {
    if (decimalValue > 999) {
      let stringValue = decimalValue.toString();

      let parts = stringValue.split(".");

      let formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let formattedValue = formattedInteger;
      if (parts.length > 1) {
        formattedValue += "." + parts[1];
      }
      return formattedValue;
    } else {
      return decimalValue.toString();
    }
  }
  return (
    <PdfContainer
      exportPDF={exportPDF}
      cardData={invoiceData}
      type="quotation"
      generatePdf={generatePdf}
    >
      <>
        <div className="position-relative col-12 padding customPageSize">
          <div className="card customCard" id="convertEd">
            <div className="card-body p-md-5 p-3">
              <div className="row mb-4 flex-nowrap">
                <div className="col-6">
                  <div className="" style={{ width: "150px", height: "auto" }}>
                    {invoiceData?.organization?.iconUrl && (
                      <img
                        className="mb-2 rounded"
                        src={invoiceData.organization.iconUrl}
                        width="100%"
                        height="auto"
                        alt="Organization Logo"
                      />
                    )}
                  </div>
                  <h3
                    id="organizationName"
                    className="text-dark2 h3_font font-weight-bold semiBoldGilroy "
                    style={{
                      color: "rgb(19, 39, 52)",
                      fontWeight: "1100",
                      marginTop: "5px",
                      fontSize: isSmallScreen ? "15px" : "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {invoiceData?.organization?.name}
                  </h3>
                  <div
                    id="organizationAddress"
                    className="GilroyMedium font-weight-bold dynamicStyle"
                    style={{
                      color: "#383c3f",
                      fontWeight: "900",
                      marginTop: "5px",
                      fontSize: "16px",
                    }}
                  >
                    {invoiceData?.organization?.profileUrl}
                  </div>
                  {invoiceData?.organization?.phone && (
                    <div
                      id="organizationPhone"
                      className="semiBoldGilroy font-weight-bold"
                      style={{ fontSize: "16px" }} // Adjust the size as needed
                    >
                      Phone :{" "}
                      <a href={`tel:${invoiceData.organization.phone}`}>
                        {invoiceData.organization.phone}
                      </a>
                    </div>
                  )}

                  {/* <div id="organizationAddress" className="GilroyMedium font-weight-bold">{invoiceData?.organization?.profileUrl}</div> */}
                  {invoiceData?.organization?.address && (
                    <div
                      id="organizationAddress"
                      className="semiBoldGilroy font-weight-bold"
                      style={{
                        color: "rgb(19, 39, 52)",
                        fontWeight: "900",
                        marginTop: "5px",
                        fontSize: isSmallScreen ? "13px" : "16px",
                      }}
                    >
                      Address : {invoiceData?.organization?.address}
                    </div>
                  )}
                  <div
                    id="organizationAddress"
                    className="GilroyMedium font-weight-bold"
                  >
                    {invoiceData?.organization?.website?.trim() ? (
                      <a
                        href={`http://${invoiceData.organization.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="website-link"
                        style={{ display: "block", paddingTop: "5.5px" }}
                      >
                        Open Website
                      </a>
                    ) : null}
                  </div>
                  {invoiceData?.organization?.socialLinks?.facebook && (
                    <div
                      id="organizationAddress"
                      className="GilroyMedium font-weight-bold"
                    >
                      Facebook:{" "}
                      <a
                        href={
                          "http://" +
                          invoiceData?.organization?.socialLinks?.facebook
                        }
                      >
                        {" "}
                        {invoiceData?.organization?.socialLinks?.facebook}
                      </a>
                    </div>
                  )}
                  {invoiceData?.organization?.socialLinks?.instagram && (
                    <div
                      id="organizationAddress"
                      className="GilroyMedium font-weight-bold"
                    >
                      Instagram:{" "}
                      <a
                        href={
                          "http://" +
                          invoiceData?.organization?.socialLinks?.instagram
                        }
                      >
                        {invoiceData?.organization?.socialLinks?.instagram}
                      </a>
                    </div>
                  )}
                  {invoiceData?.organization?.socialLinks?.linkedin && (
                    <div
                      id="organizationAddress"
                      className="GilroyMedium font-weight-bold"
                    >
                      LinkedIn:{" "}
                      <a
                        href={
                          "http://" +
                          invoiceData?.organization?.socialLinks?.linkedin
                        }
                      >
                        {" "}
                        {invoiceData?.organization?.socialLinks?.linkedin}
                      </a>
                    </div>
                  )}
                  {invoiceData?.organization?.socialLinks?.twitter && (
                    <div
                      id="organizationAddress"
                      className="GilroyMedium font-weight-bold"
                    >
                      Twitter:{" "}
                      <a
                        href={
                          "http://" +
                          invoiceData?.organization?.socialLinks?.twitter
                        }
                      >
                        {invoiceData?.organization?.socialLinks?.twitter}
                      </a>
                    </div>
                  )}
                </div>
                <div
                  className="col-6 d-flex "
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <h3 className="mb-1 h3_font font-weight-boldz ">
                    <span
                      style={{
                        color: "#132734 ",
                      }}
                      className="order_heading"
                    >
                      INVOICE
                    </span>
                  </h3>
                  <div
                    className="semiBoldGilroy customOrder"
                    style={{
                      color: "#132734 ",
                      fontWeight: "900",
                      marginBottom: "3px",
                      fontSize: isSmallScreen ? "15px" : "16px",
                      textAlign: "end",
                    }}
                  >
                    Invoice No. :{" "}
                    <span
                      className="semiBoldGilroy quotationNo"
                      style={{ marginLeft: "10px" }}
                    >
                      {invoiceData?.invoiceId || "N/A"}
                    </span>
                  </div>
                  <div
                    className="semiBoldGilroy customOrder"
                    style={{
                      color: "#132734 ",
                      fontWeight: "900",
                      marginBottom: "3px",
                      fontSize: isSmallScreen ? "15px" : "16px",
                      textAlign: "end",
                    }}
                  >
                    Invoice Date :{" "}
                    <span
                      className="semiBoldGilroy quotDate"
                      style={{ marginLeft: "10px" }}
                    >
                      {createDate(invoiceData?.createdAt) || "N/A"}
                    </span>
                  </div>
                  {invoiceData?.validity && (
                    <div
                      className="semiBoldGilroy  customOrder"
                      style={{
                        color: "#132734 ",
                        fontWeight: "900",
                        marginBottom: "3px",
                        fontSize: isSmallScreen ? "15px" : "16px",
                        textAlign: "end",
                      }}
                    >
                      Valid upto:{" "}
                      <span className="semiBoldGilroy">
                        {invoiceData?.validity} days
                      </span>
                    </div>
                  )}
                  {/* <div className="GilroyBold ">Customer References <span className="GilroyRegular">AF4725</span>
                                    </div> */}
                  <div
                    className="semiBoldGilroy customOrder"
                    style={{
                      color: "#132734 ",
                      fontWeight: "900",
                      marginBottom: "3px",
                      fontSize: isSmallScreen ? "15px" : "16px",
                      textAlign: "end",
                    }}
                  >
                    Prepared By :{" "}
                    <span className="semiBoldGilroy">
                      {invoiceData?.createdBy}
                      {/* {invoiceData?.phone} */}
                    </span>
                  </div>
                  {/* <div
                    className="semiBoldGilroy customOrder"
                    style={{
                      color: "#132734 ",
                      fontWeight: "900",
                      marginBottom: "3px",
                      fontSize: "16px",
                    }}
                  >
                    Created by :
                    <span className="semiBoldGilroy">
                      {invoiceData?.createdBy}
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="pt-4" style={{ borderTop: "2px solid #f5f5f5" }}>
                <div className="row mb-4">
                  <div className="col-sm-8">
                    <h3
                      className="text-dark font-weight-bold semiBoldGilroy mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      Invoice For :
                    </h3>
                    <div
                      className="semiBoldGilroy bcolor order_font_size_16"
                      style={{ marginBottom: "2px" }}
                    >
                      Name : {invoiceData?.lead?.name}
                    </div>
                    <div className="semiBoldGilroy order_font_size_16">
                      Phone no : {invoiceData?.lead?.phone}
                    </div>
                    <div className="semiBoldGilroy order_font_size_16">
                      Email : {invoiceData?.lead?.email}
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive-sm">
                <table id="products_table" className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="center semiBoldGilroy fs18  text-center col-1 order_font_size_15">
                        #
                      </th>
                      <th className="center semiBoldGilroy fs18 col-3 order_font_size_15">
                        Name
                      </th>
                      <th className="center semiBoldGilroy fs18 col-2">
                        Description
                      </th>
                      <th className="right semiBoldGilroy fs18  text-right col-2 order_font_size_15">
                        Unit Price
                      </th>
                      <th className="center semiBoldGilroy fs18  text-right  col-2 order_font_size_15">
                        Qty
                      </th>
                      <th className="right semiBoldGilroy fs18  text-right  col-2 order_font_size_15">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData?.products?.map((product, index) => {
                      return (
                        <tr style={borderBottom}>
                          <td className="text-center  col-1">{index + 1}</td>
                          <td
                            style={{
                              fontSize: isSmallScreen ? "15px" : "16px",
                            }}
                            className="semiBoldGilroy  col-3"
                          >
                            {product.name}
                          </td>
                          <td style={{ fontSize: "13px" }} className=" col-2">
                            {product.description}
                          </td>
                          <td
                            style={{ fontSize: "14px" }}
                            className="text-right  col-2"
                          >
                            Rs.{" "}
                            {product.unitPrice &&
                              formatWithCommas(product.unitPrice)}
                          </td>
                          <td
                            className="text-right  col-2"
                            style={{ fontSize: "14px" }}
                          >
                            {product.quantity}
                          </td>
                          <td
                            style={{ fontSize: "14px" }}
                            className="text-right  col-2"
                          >
                            {/* {currencySymbol} */} Rs.{" "}
                            {formatWithCommas(
                              product.unitPrice * product.quantity
                            )}
                            .00
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row">
                {/* Received Amount */}

                {invoiceData.receivedAmount ? (
                  <>
                    <div className="order_sub_total mt-3">
                      <span className="font-weight-bold semiBoldGilroy">
                        Payment Mode
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                        className="semiBoldGilroy col-5 text-end col-md-2"
                      >
                        {invoiceData?.receivedPaymentMode}
                      </span>
                    </div>

                    <div className="order_sub_total mt-3">
                      <span className="font-weight-bold semiBoldGilroy">
                        Received Amount
                      </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="semiBoldGilroy col-5 text-end col-md-2"
                      >
                        Rs.{" "}
                        {invoiceData.receivedAmount &&
                          formatWithCommas(invoiceData.receivedAmount)}
                        .00
                      </span>
                    </div>
                  </>
                ) : null}

                {/* Subtotal */}
                <div className="col-12 ml-auto">
                  <div className="order_sub_total mt-3">
                    <span
                      className="semiBoldGilroy"
                      style={{ fontWeight: "500" }}
                    >
                      Subtotal
                    </span>
                    <span
                      style={{ fontSize: "14px" }}
                      className="semiBoldGilroy col-5 text-end col-md-2"
                    >
                      Rs.{" "}
                      {invoiceData.subTotal &&
                        formatWithCommas(invoiceData.subTotal)}
                      .00
                    </span>
                  </div>

                  {/* Discount */}
                  <div className="order_sub_total mt-3">
                    <span className="font-weight-bold">
                      Discount (
                      {invoiceData?.discount?.type === "fixed"
                        ? "fixed"
                        : invoiceData?.discount?.rate + "%"}
                      )
                    </span>
                    <span
                      style={{ fontSize: "14px" }}
                      className="font-weight-bold col-4 text-end col-md-2"
                    >
                      Rs.{" "}
                      {invoiceData?.discount?.amount &&
                        formatWithCommas(invoiceData.discount.amount)}
                    </span>
                  </div>

                  {invoiceData?.additionalCharges?.length > 0 ? (
                    <>
                      {invoiceData?.additionalCharges?.map((charge) => {
                        return (
                          <div className="order_sub_total mt-3">
                            <span>
                              {charge.title}{" "}
                              {charge?.type === "fixed"
                                ? `(${charge?.type})`
                                : `(${charge?.rate}%)`}
                            </span>
                            <span
                              style={{ fontSize: "14px" }}
                              className="text-right"
                            >
                              Rs. {formatWithCommas(charge.amount)}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : null}

                  {invoiceData?.taxes?.length > 0 ? (
                    <>
                      {invoiceData?.taxes?.map((tax) => {
                        return (
                          <div className="order_sub_total mt-3">
                            <td>
                              {tax.title} ({tax.rate}%)
                            </td>
                            <td
                              style={{ fontSize: "14px" }}
                              className="text-right"
                            >
                              ₹ {tax.amount}
                            </td>
                          </div>
                        );
                      })}
                    </>
                  ) : null}

                  <div
                    className="order_total_button"
                    style={{ backgroundColor: "#ebf0f4" }}
                  >
                    <span
                      style={{
                        color: "#132734",
                        fontWeight: "bold",
                        fontSize: "16px", // You can adjust this value as needed
                      }}
                    >
                      TOTAL -
                    </span>
                    <span
                      style={{
                        color: "#132734",
                        fontWeight: "bold",
                        fontSize: "16px", // You can adjust this value as needed
                      }}
                    >
                      Rs.{" "}
                      {invoiceData.total && formatWithCommas(invoiceData.total)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <div
                    style={{
                      padding: "14px",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#333",
                        marginBottom: "8px",
                      }}
                    >
                      Total in Words
                    </h4>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#555",
                        margin: 0,
                        wordWrap: "break-word",
                      }}
                    >
                      INR :{" "}
                      {invoiceData?.total &&
                        decimalToEnglish(invoiceData.total)}
                    </p>
                  </div>
                </div>
              </div>
              {invoiceData?.notes || invoiceData.bankDetails ? (
                <div className="row mb-4">
                  <div
                    className=""
                    style={{
                      background: "#f0f3f8",
                      borderRadius: "5px",
                      padding: "15px 20px",
                      maxWidth: "450px",
                      width: isSmallScreen ? "95%" : "100%",
                      margin: isSmallScreen ? "auto" : "",
                    }}
                  >
                    {invoiceData?.notes ? (
                      <>
                        <h4
                          className="font-weight-bold semiBoldGilroy mb-1"
                          style={{ fontSize: "14px" }}
                        >
                          Terms & Conditions
                        </h4>
                        <div
                          className="GilroyBold mb-1"
                          style={{ fontSize: "14px" }}
                        >
                          <span className="font-weight-bold semiBoldGilroy">
                            Notes:
                          </span>{" "}
                          {invoiceData?.notes}
                        </div>
                      </>
                    ) : null}

                    {invoiceData?.bankDetails?.bankName && (
                      <div className="mt-4">
                        <h4
                          className="mb-2"
                          style={{ fontSize: "16px", fontWeight: 700 }}
                        >
                          Bank Details
                        </h4>
                        <div className="mb-1">
                          <span style={{ fontWeight: 600 }}>Bank Name:</span>{" "}
                          {invoiceData?.bankDetails?.bankName}
                        </div>
                        <div className="mb-1">
                          <span style={{ fontWeight: 600 }}>IFSC Code:</span>{" "}
                          {invoiceData?.bankDetails?.ifscCode}
                        </div>
                        <div className="mb-1">
                          <span style={{ fontWeight: 600 }}>
                            Account Number:
                          </span>{" "}
                          {invoiceData?.bankDetails?.accountNumber}
                        </div>
                        <div>
                          <span style={{ fontWeight: 600 }}>
                            Account Holder:
                          </span>{" "}
                          {invoiceData?.bankDetails?.accountHolderName}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className="position-absolute d-flex justify-content-end align-items-center">
            <button
              id="generate_pdf"
              className="savePdfBtn px-3 py-1 rounded GilroyMedium"
              onClick={() => convertToPDF()}
            >
              Save as Pdf
            </button>
          </div> */}
        </div>
      </>
    </PdfContainer>
  );
};

export default Invoice;
