import { savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { uploadFiles } from "../constants/ApiCall";
import { saveAs } from "file-saver"; // Import FileSaver.js
import { jwtDecode } from "jwt-decode";

class DocService {
  createPdf = (html) => {
    savePDF(html, {
      paperSize: "A6",
      margin: { top: "1cm", left: "1cm", right: "1cm", bottom: "1cm" },
      scale: 0.5,
      fileName: "Business-card.pdf",
    });
  };

  exportPDF = (html, data, type, scale) => {
    let gridElement = html;
    drawDOM(gridElement, {
      paperSize: `${type === "businessCard" ? "A6" : "Letter"}`,
      margin: { top: "1cm", left: "1cm", right: "1cm", bottom: "1cm" },
      scale: scale,
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then(async (dataUri) => {
        // Convert dataUrl into blob using browser fetch API
        const blob = await (await fetch(dataUri)).blob();

        // Create file form the blob
        const pdf = new File(
          [blob],
          `{${
            type == "businessCard"
              ? data.name + "digital business card.pdf"
              : "quotation.pdf"
          }}`,
          { type: blob.type }
        );

        //      // Save the file locally
        // saveAs(pdf,"Test.pdf");  // Save the PDF to the local system
        var formData = new FormData();
        formData.append(
          `${type === "businessCard" ? data.name : type || "quotation"}`,
          pdf
        );
        formData.append(
          "type",
          `${type == "businessCard" ? "digital_card" : type || "quotation"}`
        );
        if (type == "businessCard") {
          const url = new URL(window.location.href);
          const parameterValue = url.searchParams.get("token");

          const decoded = jwtDecode(parameterValue);

          formData.append("user", decoded?.user?.sub);
        } else {
          formData.append(type, data._id);
        }

        await uploadFiles(formData);

        if (window?.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage("File Uploaded");
        }

        // Check if the device is able to share these files then open share dialog
        // if (navigator.canShare && navigator.canShare({ files: [pdf] })) {
        //   try {
        //     await navigator.share({
        //       files: [pdf],         // Array of files to share
        //       title: 'Business Card'  // Share dialog title
        //     })
        //   } catch (error) {
        //     console.log('Sharing failed!', error)
        //   }
        // } else {
        //   console.log('This device does not support sharing files.')
        // }
      });
  };
}

const Doc = new DocService();
export default Doc;
