import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
} from "../constants/ApiCall";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import Header from "../Components/Integration Components/Header";
import Integration from "../Components/Integration Components/Integration";
import Button from "../Components/Integration Components/Button";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

function ExporterIndia(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [integration, setIntegration] = useState(null);
  const [integrationDate, setIntegrationDate] = useState(new Date());
  const [integrationDetails, setIntegrationDetails] = useState({});

  useEffect(() => {
    getIntegration();
  }, []);

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "exporters_india"
      );
      if (_integration) {
        setIntegration(_integration);
        setIntegrationDetails(_integration.details[0]);
        setData(_integration.details[0]);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setLoadingText("Connecting...");
      let payload = [
        {
          key: data.key,
          email: data.email,
        },
      ];
      const response = await createUserIntegration("exporters_india", payload);
      setLoading(false);
      if (response && response.status) {
        props.history.push({
          pathname: `/integration/success`,
        });
      } else {
        NotificationManager.error("Error in integration");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    const response = await disconnectIntegration(
      integration?.userIntegrationId
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      alert("Error in integration");
    }
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 19px",
      "& .form_lbl": {
        fontFamily: "'Gilroy-SemiBold' !important",
      },
      "& button": {
        position: "relative",
        transform: "translateX(-50%)",
        left: "50%",
        width: "330px",
        marginBottom: "30px !important",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      marginBottom: "10px",
    },
    infoText: {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#333333",
      marginBottom: "15px",
    },
    input: {
      width: "100%",
      minWidth: "331px",
      height: "39px",
      background: "#FFFFFF",
      borderRadius: "10px",
      border: "none",
      padding: "10px 13px !important",
      "&::placeholder": {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Exporter india"}
            logo={
              <img src="/assets/images/ExportersIndia.webp" alt="ExportersIndia" width="43" height="37" />
            }
          />
          <div className={classes.text}>
            Connect your Exporter India account and automatically import your inquiries
          </div>
          {integration ? (
            <div className={"pt-2 mt-2 " + classes.text}>
              Exporter India Connected successfully on{" "}
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          ) : (
            <>
              <div className={classes.text + " mt-3"}>
                How does Exporter India integration work?
              </div>
              <div className={classes.infoText}>
                1. Enter your Exporter India CRM key and associated email address below.
              </div>
              <div className={classes.infoText}>
                2. Once connected, we'll automatically import your inquiries from Exporter India.
              </div>
              <div className={classes.infoText}>
                3. New inquiries will be synced regularly, keeping your leads up to date.
              </div>
              <div className={classes.infoText}>
                4. You can manage and respond to these inquiries directly from our platform.
              </div>
              <div className={classes.infoText}>
                5. This integration streamlines your workflow and ensures you never miss a potential lead.
              </div>
            </>
          )}
          <div className="mt-4 mb-2 form_lbl">CRM key</div>
          <input
            type="text"
            autoComplete="off"
            name="key"
            className={"mb-2 " + classes.input}
            onChange={handleChange}
            value={data?.key}
            placeholder="Enter CRM key"
          />
          <div className="mt-1 mb-2 form_lbl"></div>
          <input
            type="text"
            autoComplete="off"
            name="email"
            className={"mb-2 " + classes.input}
            onChange={handleChange}
            value={data?.email}
            placeholder="Enter Email"
          />
          {integration ? (
            <Button onClick={handleDisconnectIntegration}>
              Disconnect
            </Button>
          ) : (
            <Button onClick={onSubmit}>
              Connect
            </Button>
          )}
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default ExporterIndia;