const GoogleClientService = {
    "web": {
        "client_id": "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com",
        "project_id": "sigma-8207c",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_secret": "2QJ4n_cwXzXDxqKY30MmcOgI",
        "redirect_uris": [
            "https://integration.3sigmacrm.com"
        ],
        "javascript_origins": [
            "http://localhost",
            "http://localhost:5000",
            "https://sigma-8207c.firebaseapp.com",
            "http://localhost:3000",
            "https://google.com",
            "https://integration.3sigmacrm.com"
        ]
    }
}

export {GoogleClientService}