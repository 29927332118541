import React, { useState, useEffect } from "react";
// import { IoIosCall } from 'react-icons/io';
// import { AiOutlineWhatsApp } from 'react-icons/ai';
// import { AiOutlinePlus } from 'react-icons/ai';
// import { IoMdPersonAdd } from 'react-icons/io';
// import { CgMail } from 'react-icons/cg';
// import { ImEarth } from 'react-icons/im';
// import { useRef } from 'react';
import Doc from "../Components/PdfDocService";
import PdfContainer from "../Components/PdfContainer";
import { getQuotation, getUser, uploadFiles } from "../constants/ApiCall";
import "./quotation.module.css";
import "./custom.module.css";
import axios from "axios";
import html2pdf from "html2pdf.js";

const borderBottom = {
  borderBottom: "1px solid #eee",
};

const Quotation = () => {
  const exportPDF = (html) => Doc.exportPDF(html, quotationData, "quotation", 0.6);

  const [bucketUrl, setBucketUrl] = useState({});
  const [quotationData, setQuotationData] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [vcfPath, setVcfPath] = useState("");

  useEffect(() => {
    fetchQuotationData();

    async function fetchQuotationData() {
      const url = new URL(window.location.href);
      const id = url.pathname.split("/")[2];
      const parameterValue = url.searchParams.get("token");
      console.log("parameterValue", parameterValue);
      var response = await axios.get(
        `https://mapi2.3sigmacrm.com/api/v2/quotation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${parameterValue}`,
            // "token-check-disable":true
            // test
          },
        }
      );
      console.log("response", response);
      setQuotationData(response.data.data);
      if (response.data.data.products[0].currency === "₹") {
        setCurrencySymbol("Rs ");
      } else {
        setCurrencySymbol(response.data.data.products[0].currency);
      }
    }
  }, []);

  // useEffect(() => {
  //     cardData._id && generateVCF();
  // }, [cardData]);

  // const getQuotationData = async () => {
  //     const response = await getQuotation();
  //     // const response = await getDigitalCard();
  //     if (response && response.status) setQuotationData(response.data[0]);
  //     // if (userInfo && userInfo.status) setBucketUrl(userInfo.data.bucketUrl);
  // };
  // console.log(quotationData)
  // will uncomment
  // function numberToWords(number) {
  //   const ones = [
  //     "Zero",
  //     "One",
  //     "Two",
  //     "Three",
  //     "Four",
  //     "Five",
  //     "Six",
  //     "Seven",
  //     "Eight",
  //     "Nine",
  //     "Ten",
  //     "Eleven",
  //     "Twelve",
  //     "Thirteen",
  //     "Fourteen",
  //     "Fifteen",
  //     "Sixteen",
  //     "Seventeen",
  //     "Eighteen",
  //     "Nineteen",
  //   ];
  //   const tens = [
  //     "",
  //     "",
  //     "Twenty",
  //     "Thirty",
  //     "Forty",
  //     "Fifty",
  //     "Sixty",
  //     "Seventy",
  //     "Eighty",
  //     "Ninety",
  //   ];

  //   if (number < 20) {
  //     return ones[number];
  //   }

  //   if (number < 100) {
  //     return (
  //       tens[Math.floor(number / 10)] +
  //       (number % 10 !== 0 ? " " + ones[number % 10] : "")
  //     );
  //   }

  //   if (number < 1000) {
  //     return (
  //       ones[Math.floor(number / 100)] +
  //       " Hundred" +
  //       (number % 100 !== 0 ? " and " + numberToWords(number % 100) : "")
  //     );
  //   }

  //   if (number < 1000000) {
  //     return (
  //       numberToWords(Math.floor(number / 1000)) +
  //       " Thousand" +
  //       (number % 1000 !== 0 ? " " + numberToWords(number % 1000) : "")
  //     );
  //   }

  //   if (number < 1000000000) {
  //     return (
  //       numberToWords(Math.floor(number / 1000000)) +
  //       " Million" +
  //       (number % 1000000 !== 0 ? " " + numberToWords(number % 1000000) : "")
  //     );
  //   }
  //   return "Number out of range";
  // }
  // asdasdsad

  function decimalToEnglish(decimalNumber) {
    // Separate the integer and decimal parts
    let [integerPart, decimalPart] = decimalNumber.toString().split(".");

    // Convert integer part to English words with commas
    let integerPartWords = numberToEnglishSentence(parseInt(integerPart));

    // Convert decimal part to English words
    let decimalPartWords = "";
    if (decimalPart !== undefined) {
      decimalPartWords = numberToEnglishSentence(parseInt(decimalPart));
    }

    // Concatenate the integer and decimal parts
    let result = integerPartWords;
    if (decimalPartWords !== "") {
      result += " decimal " + decimalPartWords;
    }

    return result;
  }

  // Function to convert a number to its English word representation
  function numberToEnglishSentence(number) {
    const numbersToWordsLessThanTwenty = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const multiplesOfTen = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    function convertToWords(num) {
      if (num < 20) {
        return numbersToWordsLessThanTwenty[num];
      } else if (num < 100) {
        return (
          multiplesOfTen[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + convertToWords(num % 10) : "")
        );
      } else if (num < 1000) {
        return (
          numbersToWordsLessThanTwenty[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " " + convertToWords(num % 100) : "")
        );
      } else if (num < 1000000) {
        return (
          convertToWords(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? ", " + convertToWords(num % 1000) : "")
        );
      } else if (num < 1000000000) {
        return (
          convertToWords(Math.floor(num / 1000000)) +
          " Million" +
          (num % 1000000 !== 0 ? ", " + convertToWords(num % 1000000) : "")
        );
      } else {
        return (
          convertToWords(Math.floor(num / 1000000000)) +
          " Billion" +
          (num % 1000000000 !== 0
            ? ", " + convertToWords(num % 1000000000)
            : "")
        );
      }
    }

    if (number === 0) {
      return "Zero";
    } else {
      return convertToWords(number);
    }
  }

  // asdasdsad
  function createDate(date) {
    if (date) {
      let quotDate = new Date(date);
      return (
        quotDate.getDate() +
        "/" +
        (quotDate.getMonth() > 9
          ? quotDate.getMonth()
          : "0" + quotDate.getMonth()) +
        "/" +
        quotDate.getFullYear()
      );
    } else {
      return;
    }
  }
  let convertToPDF = () => {
    // Get the element to convert
    const element = document.getElementById("convertEd");

    // Set up options for the PDF conversion (optional)
    const options = {
      margin: 0.1,
      filename: "converted_document.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      // jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      jsPDF: { unit: "in", format: "a4", orientation: "p" },
    };

    // Call the html2pdf function
    html2pdf().from(element).set(options).save();
  };

  function formatWithCommas(decimalValue) {
    if (decimalValue > 999) {
      let stringValue = decimalValue.toString();

      let parts = stringValue.split(".");

      let formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let formattedValue = formattedInteger;
      if (parts.length > 1) {
        formattedValue += "." + parts[1];
      }
      return formattedValue;
    } else {
      return decimalValue.toString();
    }
  }
  return (
    <PdfContainer
      exportPDF={exportPDF}
      cardData={quotationData}
      type="quotation"
    >
      {console.log(quotationData, "quotationData")}
      <>
        <div className="position-relative offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 padding customPageSize">
          <div className="card customCard rounded-4" id="convertEd">
            <div className="card-body p-5">
              <div className="row mb-4 flex-nowrap">
                <div className="col-6">
                  <div className="" style={{ width: "150px", height: "auto" }}>
                    {quotationData?.organization?.iconUrl && (
                      <img
                        className="mb-2 rounded"
                        src={quotationData.organization?.iconUrl}
                        width="100%"
                        height="auto"
                      />
                    )}
                  </div>
                  <h3
                    id="organizationName"
                    className="text-dark2 h3_font font-weight-bold semiBoldGilroy "
                    style={{
                      color: "rgb(19, 39, 52)",
                      fontWeight: "900",
                      marginTop: "5px",
                      fontSize: "17px",
                    }}
                  >
                    {quotationData?.organization?.name}
                  </h3>
                  <div
                    id="organizationAddress"
                    className="GilroyMedium font-weight-bold dynamicStyle"
                    style={{
                      color: "#383c3f",
                      fontWeight: "900",
                      marginTop: "5px",
                      fontSize: "16px",
                    }}
                  >
                    {quotationData?.organization?.profileUrl}
                  </div>
                  {quotationData?.organization?.phone && (
                    <div
                      id="organizationAddress"
                      className="semiBoldGilroy  font-weight-bold"
                    >
                      Phone:{" "}
                      <a href={`tel:${quotationData?.organization?.phone}`}>
                        {quotationData?.organization?.phone}
                      </a>
                    </div>
                  )}
                  {/* <div id="organizationAddress" className="GilroyMedium font-weight-bold">{quotationData?.organization?.profileUrl}</div> */}
                  {quotationData?.organization?.address && (
                    <div
                      id="organizationAddress"
                      className="semiBoldGilroy  font-weight-bold"
                      style={{
                        color: "rgb(19, 39, 52)",
                        fontWeight: "900",
                        marginTop: "5px",
                        fontSize: "17px",
                      }}
                    >
                      Address: {quotationData?.organization?.address}
                    </div>
                  )}
                  <div
                    id="organizationAddress"
                    className="GilroyMedium font-weight-bold"
                  >
                    <a href={"http://" + quotationData?.organization?.website}>
                      {quotationData?.organization?.website}
                    </a>
                  </div>
                  {quotationData?.organization?.socialLinks?.facebook && (
                    <div
                      id="organizationAddress"
                      className="GilroyMedium font-weight-bold"
                    >
                      Facebook:{" "}
                      <a
                        href={
                          "http://" +
                          quotationData?.organization?.socialLinks?.facebook
                        }
                      >
                        {" "}
                        {quotationData?.organization?.socialLinks?.facebook}
                      </a>
                    </div>
                  )}
                  {quotationData?.organization?.socialLinks?.instagram && (
                    <div
                      id="organizationAddress"
                      className="GilroyMedium font-weight-bold"
                    >
                      Instagram:{" "}
                      <a
                        href={
                          "http://" +
                          quotationData?.organization?.socialLinks?.instagram
                        }
                      >
                        {quotationData?.organization?.socialLinks?.instagram}
                      </a>
                    </div>
                  )}
                  {quotationData?.organization?.socialLinks?.linkedin && (
                    <div
                      id="organizationAddress"
                      className="GilroyMedium font-weight-bold"
                    >
                      LinkedIn:{" "}
                      <a
                        href={
                          "http://" +
                          quotationData?.organization?.socialLinks?.linkedin
                        }
                      >
                        {" "}
                        {quotationData?.organization?.socialLinks?.linkedin}
                      </a>
                    </div>
                  )}
                  {quotationData?.organization?.socialLinks?.twitter && (
                    <div
                      id="organizationAddress"
                      className="GilroyMedium font-weight-bold"
                    >
                      Twitter:{" "}
                      <a
                        href={
                          "http://" +
                          quotationData?.organization?.socialLinks?.twitter
                        }
                      >
                        {quotationData?.organization?.socialLinks?.twitter}
                      </a>
                    </div>
                  )}
                </div>
                <div
                  className="col-6 d-flex "
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <h3
                    className=" mb-1 h3_font font-weight-bold customColor"
                    style={{
                      fontSize: "30px",
                      fontWeight: "900",
                    }}
                  >
                    <span
                      style={{
                        color: "#132734 ",
                      }}
                    >
                      Quotation
                    </span>
                  </h3>
                  <div
                    className="semiBoldGilroy customOrder"
                    style={{
                      color: "#132734 ",
                      fontWeight: "900",
                      marginBottom: "3px",
                      fontSize: "16px",
                    }}
                  >
                    Quotation Number:{" "}
                    <span className=" semiBoldGilroy quotationNo">
                      {quotationData?.quotationId}
                    </span>
                  </div>
                  <div
                    className="semiBoldGilroy customOrder"
                    style={{
                      color: "#132734 ",
                      fontWeight: "900",
                      marginBottom: "3px",
                      fontSize: "16px",
                    }}
                  >
                    Quotation Date:{" "}
                    <span className="semiBoldGilroy  quotDate">
                      {createDate(quotationData?.createdAt)}{" "}
                    </span>
                  </div>
                  {quotationData?.validity && (
                    <div
                      className="semiBoldGilroy  customOrder"
                      style={{
                        color: "#132734 ",
                        fontWeight: "900",
                        marginBottom: "3px",
                        fontSize: "16px",
                      }}
                    >
                      Valid upto:{" "}
                      <span className="semiBoldGilroy">
                        {quotationData?.validity} days
                      </span>
                    </div>
                  )}
                  {/* <div className="GilroyBold ">Customer References <span className="GilroyRegular">AF4725</span>
                                    </div> */}
                  <div
                    className="semiBoldGilroy customOrder"
                    style={{
                      color: "#132734 ",
                      fontWeight: "900",
                      marginBottom: "3px",
                      fontSize: "16px",
                    }}
                  >
                    Prepared By:{" "}
                    <span className="semiBoldGilroy">
                      {quotationData?.createdBy}
                      {/* {quotationData?.phone} */}
                    </span>
                  </div>
                  {/* <div
                    className="semiBoldGilroy customOrder"
                    style={{
                      color: "#132734 ",
                      fontWeight: "900",
                      marginBottom: "3px",
                      fontSize: "16px",
                    }}
                  >
                    Created by:
                    <span className="semiBoldGilroy">
                      {quotationData?.createdBy}
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="pt-4" style={{ borderTop: "2px solid #f5f5f5" }}>
                <div className="row mb-4">
                  <div className="col-sm-8">
                    <h3 className="text-dark h3_font font-weight-bold semiBoldGilroy ">
                    Quotation For:
                    </h3>
                    <div
                      className="semiBoldGilroy bcolor"
                      style={{ marginBottom: "2px" }}
                    >
                      Name: {quotationData?.lead?.name}
                    </div>
                    <div className="semiBoldGilroy ">
                      Phone no:{quotationData?.lead?.phone}
                    </div>
                    <div className="semiBoldGilroy ">
                      Email: {quotationData?.lead?.email}
                    </div>
                    {/* <div className="GilroyMedium">5th Floor</div> */}
                  </div>
                </div>
              </div>
              <div className="table-responsive-sm">
                <table id="products_table" className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="center semiBoldGilroy fs18  text-center col-1">
                        #
                      </th>
                      <th className="center semiBoldGilroy fs18 col-3">Name</th>
                      <th className="center semiBoldGilroy fs18 col-2">
                        Description
                      </th>
                      <th className="right semiBoldGilroy fs18  text-right  col-2">
                        Unit Price
                      </th>
                      <th className="center semiBoldGilroy fs18  text-right  col-2">
                        Qty
                      </th>
                      <th className="right semiBoldGilroy fs18  text-right  col-2">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotationData?.products?.map((product, index) => {
                      return (
                        <tr style={borderBottom}>
                          <td className="text-center  col-1">{index + 1}</td>
                          <td
                            style={{ fontSize: "16px" }}
                            className="semiBoldGilroy  col-3"
                          >
                            {product.name}
                          </td>
                          <td style={{ fontSize: "13px" }} className=" col-2">
                            {product.description}
                          </td>
                          <td
                            style={{ fontSize: "14px" }}
                            className="text-right  col-2"
                          >
                            {product.currency}{" "}
                            {product.unitPrice &&
                              formatWithCommas(product.unitPrice)}
                          </td>
                          <td
                            className="text-right  col-2"
                            style={{ fontSize: "14px" }}
                          >
                            {product.quantity}
                          </td>
                          <td
                            style={{ fontSize: "14px" }}
                            className="text-right  col-2"
                          >
                            {/* {currencySymbol} */} {product.currency}{" "}
                            {formatWithCommas(
                              product.unitPrice * product.quantity
                            )}
                            .00
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className=" col-12 ml-auto">
                  <table id="footer_table" className="table">
                    <tbody>
                      <tr>
                        <td className="text-right tdFooter font-weight-bold pb-0  col-10 semiBoldGilroy ">
                          Subtotal
                        </td>
                        <td
                          style={{ fontSize: "14px" }}
                          className="text-right pb-0  col-2 semiBoldGilroy "
                        >
                          {quotationData?.products?.[0].currency}{" "}
                          {quotationData.subTotal &&
                            formatWithCommas(quotationData.subTotal)}
                          .00
                        </td>
                      </tr>
                      <tr>
                        <td className="text-right tdFooter font-weight-bold pb-0  ">
                          Discount (
                          {quotationData?.discount?.type === "fixed"
                            ? "fixed"
                            : quotationData?.discount?.rate + "%"}
                          )
                        </td>
                        <td
                          style={{ fontSize: "14px" }}
                          className="text-right tdFooter pb-0  "
                        >
                          {quotationData?.products?.[0].currency}{" "}
                          {quotationData?.discount?.amount &&
                            formatWithCommas(quotationData.discount.amount)}
                        </td>
                      </tr>
                      {quotationData?.additionalCharges?.length > 0 ? (
                        <>
                          {quotationData?.additionalCharges?.map((charge) => {
                            return (
                              <tr>
                                <td className="text-right tdFooter">
                                  {charge.title}{" "}
                                  {charge?.type === "fixed"
                                    ? `(${charge?.type})`
                                    : `(${charge?.rate}%)`}
                                  {/* {charge?.type === 'rate' ? '%' : ''} */}
                                </td>
                                <td
                                  style={{ fontSize: "14px" }}
                                  className="text-right tdFooter"
                                >
                                  {/* {currencySymbol} */}
                                  {quotationData?.products?.[0].currency}{" "}
                                  {formatWithCommas(charge.amount)}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : null}
                      {quotationData?.taxes?.length > 0 ? (
                        <>
                          {quotationData?.taxes?.map((tax) => {
                            return (
                              <tr>
                                <td className="text-right tdFooter pt-0">
                                  {tax.title} ({tax.rate}%)
                                </td>
                                <td
                                  style={{ fontSize: "14px" }}
                                  className="text-right tdFooter pt-0"
                                >
                                  {/* {currencySymbol} */}₹ {tax.amount}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : null}
                      <tr className="customColor">
                        <td
                          className="text-right tdFooter font-weight-bold p-0"
                          style={{ position: "relative" }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              background: "#132734",
                              color: "white",
                              height: "100%",
                              position: "absolute",
                              padding: "15px",
                              right: "0px",
                              borderTopLeftRadius: "5px",
                              borderBottomLeftRadius: "5px",
                              top: "10px",
                            }}
                          >
                            TOTAL
                          </span>
                        </td>
                        <td
                          className="text-right tdFooter font-weight-bold"
                          style={{
                            fontSize: "16px",
                            background: "#132734",
                            color: "white",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            position: "relative",
                            top: "10px",
                          }}
                        >
                          <span style={{ color: "white" }}>
                            {/* {currencySymbol} */}₹{" "}
                            {quotationData.total &&
                              formatWithCommas(quotationData.total)}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="totalInWordsWrapper col-8">
                  <p>Total in words</p>
                  <p className="priceInWords semiBoldGilroy">
                    INR:{" "}
                    {quotationData?.total &&
                      decimalToEnglish(quotationData.total)}
                  </p>
                </div>
              </div>
              {quotationData?.notes || quotationData.bankDetails ? (
                <div className="row mb-4">
                  <div
                    className=""
                    style={{
                      background: "#f0f3f8",
                      borderRadius: "5px",
                      padding: "15px 20px",
                      width: "450px",
                    }}
                  >
                    {quotationData?.notes ? (
                      <>
                        <h4 className="font-weight-bold semiBoldGilroy  mb-1">
                          Terms & Conditions
                        </h4>
                        <div className=" GilroyBold  mb-1">
                          <span className="font-weight-bold semiBoldGilroy">
                            {" "}
                            Notes:
                          </span>{" "}
                          {quotationData?.notes}
                        </div>
                      </>
                    ) : null}

                    {quotationData?.bankDetails?.bankName && (
                      <h4 className="text-dark mb-1 GilroyBold h4_font font-weight-bold semiBoldGilroy ">
                        Bank Details
                      </h4>
                    )}
                    {quotationData?.bankDetails?.bankName && (
                      <div className="semiBoldGilroy ">
                        Bank Name: {quotationData?.bankDetails?.bankName}
                      </div>
                    )}
                    {quotationData?.bankDetails?.ifscCode && (
                      <div className="semiBoldGilroy ">
                        IFSC Code: {quotationData?.bankDetails?.ifscCode}
                      </div>
                    )}
                    {quotationData?.bankDetails?.accountNumber && (
                      <div className="semiBoldGilroy ">
                        Account Number:{" "}
                        {quotationData?.bankDetails?.accountNumber}
                      </div>
                    )}
                    {quotationData?.bankDetails?.accountHolderName && (
                      <div className="semiBoldGilroy ">
                        Account Holder:{" "}
                        {quotationData?.bankDetails?.accountHolderName}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="position-absolute d-flex justify-content-end align-items-center">
            <button
              id="generate_pdf"
              className="savePdfBtn px-3 py-1 rounded GilroyMedium"
              onClick={() => convertToPDF()}
            >
              Save as Pdf
            </button>
          </div>
        </div>
      </>
    </PdfContainer>
  );
};

export default Quotation;
