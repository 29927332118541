import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import { makeStyles } from "@material-ui/core/styles";
import { getGoogleTokens } from "../constants/ApiCall";
import moment from "moment";

import Integration from "../Components/Integration Components/Integration";
import Header from "../Components/Integration Components/Header";
import Button from "../Components/Integration Components/Button";
import Logo from "../images/gmail.png";

// UTILS CALL
import { API_URL } from "../constants/utils";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleClientService } from "../constants/Google_client_service";

const axios = require("axios");

function Gmail(props) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [integration, setIntegration] = useState(true);
  const [integrationDate, setIntegrationDate] = useState(new Date());

  const googleAuth = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      const tokens = await axios.post(`${GoogleClientService.web.token_uri}`, {
        code: codeResponse.code,
        grant_type: "authorization_code",
        client_id: `${GoogleClientService.web.client_id}`,
        client_secret: `${GoogleClientService.web.client_secret}`,
        redirect_uri: `${GoogleClientService.web.redirect_uris[0]}`,
      });
      getGoogleTokens({
        tokens: {
          access_token: `${tokens.data.access_token}`,
          scope: `${tokens.data.scope}`,
          token_type: `${tokens.data.token_type}`,
          id_token: `${tokens.data.id_token}`,
          expiry_date: tokens.data.expires_in,
          refresh_token: `${tokens.data.refresh_token}`,
        },
      });
    },
    scope: "https://mail.google.com/",
    onError: (errorResponse) => console.log(errorResponse),
  });

  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 13px 0 17px",
      "& .integration_type": {
        gap: "12px",
      },
      "& button": {
        width: "330px",
        position: "relative",
        marginBottom: "50px !important",
        marginTop: "70px !important",
        display: "flex",
        alignItems: "center",
        paddingLeft: "33px",
        gap: "9px"
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      margin: "0",
    },
    iframe: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
    }
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Gmail"}
            logo={<img src={Logo} alt="Gmail" width="40" height="40" />}
          />
          <div className={classes.text}>
            Now autosync your new and existing Gooel mail emails to 3sigma CRM
            easily, you will be able to bulk send emails directly and create
            activities easily.
          </div>
          {integration ? (
            <div className={classes.text + " mt-4 mb-4 pb-1"}>
              <a
                href="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
                target="_blank"
                style={{ color: "inherit", textDecoration: "underline" }}
                className=" d-flex align-items-center gap-2"
              >
                How does Google mail integration work?
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 10L13.19 7L8 4V10ZM19.56 2.17C19.69 2.64 19.78 3.27 19.84 4.07C19.91 4.87 19.94 5.56 19.94 6.16L20 7C20 9.19 19.84 10.8 19.56 11.83C19.31 12.73 18.73 13.31 17.83 13.56C17.36 13.69 16.5 13.78 15.18 13.84C13.88 13.91 12.69 13.94 11.59 13.94L10 14C5.81 14 3.2 13.84 2.17 13.56C1.27 13.31 0.69 12.73 0.44 11.83C0.31 11.36 0.22 10.73 0.16 9.93C0.0900001 9.13 0.0599999 8.44 0.0599999 7.84L0 7C0 4.81 0.16 3.2 0.44 2.17C0.69 1.27 1.27 0.69 2.17 0.44C2.64 0.31 3.5 0.22 4.82 0.16C6.12 0.0899998 7.31 0.0599999 8.41 0.0599999L10 0C14.19 0 16.8 0.16 17.83 0.44C18.73 0.69 19.31 1.27 19.56 2.17Z"
                    fill="#9F1212"
                  />
                </svg>
              </a>
            </div>
          ) : (
            <div className={classes.text + " mt-4 ms-3 mb-4 pb-1"}>
              How does Google mail integration work?
            </div>
          )}
          {!integration ? (
            <div className={classes.iframe + " iframe_container"}>
              <iframe
                width="300"
                height="150"
                frameBorder="0"
                title="youtube"
                style={{ borderRadius: "10px" }}
                src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
              ></iframe>
            </div>
          ) : (
            <div className={classes.text}>
              Google Mail connected successfully on {" "}
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          )}
          {integration ? (
            <Button
              onClick={() => alert("disconnect button clicked")}
            >
              Disconnect
            </Button>
          ) : (
            <Button
              onClick={googleAuth}
            >
              Connect
            </Button>
          )}
        </div>
      </LoadingOverlay>
    </BottomSheet>
  );
}

export default Gmail;
