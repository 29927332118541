import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { IoIosCall } from "react-icons/io";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdPersonAdd } from "react-icons/io";
import { CgMail } from "react-icons/cg";
import { ImEarth } from "react-icons/im";
import { useRef } from "react";
import Doc from "../Components/PdfDocService";
import PdfContainer from "../Components/PdfContainer";
// import vCardsJS from "vcards-js";
import { getDigitalCard, getUser, uploadFiles } from "../constants/ApiCall";
import { RiFacebookCircleFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import LoadingOverlay from "react-loading-overlay";

const outerStyle = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-betwen",
  backgroundColor: "#EBEFF4",
  borderRadius: "6px",
  position: "relative",
  padding: "0 5%",
  marginBottom: 20,
};
const logoStyle = {
  borderRadius: "50%",
  marginTop: "12px",
};

const iconStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  fontSize: "clamp(1.5rem, 3vw, 1.5rem)",
};

const contactDetails = {
  color: "black",
  fontSize: "clamp(1rem, 2vw, 1rem)",
  fontFamily: "Gilroy-SemiBold",
};

const footerStyle = {
  backgroundColor: "#00A0DC",
  color: "white",
  width: "100%",
  textAlign: "center",
  padding: "12px 8px",
  borderRadius: "6px",
  position: "absolute",
  bottom: 0,
};

const absStyle = {
  position: "absolute",
  bottom: "60px",
  right: "5px",
  backgroundColor: "rgb(0, 160, 220)",
  height: "40px",
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  color: "white",
};

const BussinessCard = () => {
  const generatePdf = (html) => Doc.createPdf(html);
  const exportPDF = (html) =>
    Doc.exportPDF(html, cardData, "businessCard", 0.5);

  const [bucketUrl, setBucketUrl] = useState({});
  const [cardData, setCardData] = useState({});
  const [vcfPath, setVcfPath] = useState("");
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    // Change the body background color when the component mounts
    document.body.style.backgroundColor = "#fff";

    // Cleanup function to revert the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  useEffect(() => {
    getCardDetails();

    // document.body.style.backgroundColor = "red";
  }, []);

  useEffect(() => {
    // cardData._id && generateVCF();
  }, [cardData]);

  const getCardDetails = async () => {
    try {
      setLoading(true);
      const userInfo = await getUser();
      const response = await getDigitalCard();

      if (response && response.status) setCardData(response.data);
      if (userInfo && userInfo.status) setBucketUrl(userInfo.data.bucketUrl);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // async function generateVCF() {
  //     var vCard = vCardsJS();

  //     vCard.firstName = `${cardData.name}`;
  //     vCard.organization = `${cardData.companyName}`;
  //     vCard.photo.attachFromUrl(
  //         `${cardData.profile?.filePath ? cardData.profile.filePath : 'https://cdn-icons-png.flaticon.com/512/149/149071.png?w=740&t=st=1690113222~exp=1690113822~hmac=d249b318d7bc404c804d07f2f1fc8784017940ee0ec8d084b153a17b32910e03'}`,
  //         "JPEG"
  //     );
  //     vCard.workPhone = `${cardData.phone}`;
  //     vCard.email = `${cardData.email}`;
  //     vCard.title = `${cardData.designation}`;
  //     vCard.url = `${cardData.website}`;

  //     const vcfText = vCard.getFormattedString()
  //     const file = new Blob([vcfText], { type: "text/x-vcard" });

  //     var formData = new FormData();
  //     formData.append('vCard', file, `${cardData.name}`)
  //     formData.append('user', cardData._id);
  //     formData.append('type', 'vcf_file');

  //     const res = await uploadFiles(formData);
  //     setVcfPath(res.data[0].filePath)
  // }

  if (loading) {
    return (
      <LoadingOverlay
        active={loading}
        spinner
        text={"Loading"}
      ></LoadingOverlay>
    );
  }

  return (
    <PdfContainer
      exportPDF={exportPDF}
      cardData={cardData}
      vcfPath={vcfPath}
      generatePdf={generatePdf}
      type="businessCard"
    >
      <LoadingOverlay active={loading} spinner text={"Loading"}>
        <div
          id="business_card"
          style={outerStyle}
          className="business_container"
        >
          <div className="business_top_container">
            {/* {cardData?.profile?.filePath ? (
              <img
                src={`${
                  cardData.profile?.filePath
                    ? bucketUrl + cardData.profile.filePath
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png?w=740&t=st=1690113222~exp=1690113822~hmac=d249b318d7bc404c804d07f2f1fc8784017940ee0ec8d084b153a17b32910e03"
                }`}
                height={80}
                width={80}
                style={logoStyle}
              />
            ) : null} */}

            <div className="m-auto">
              <h1 className="mt-3 business_name">{cardData?.name ?? ""}</h1>
              <h5 className="business_designation">
                {cardData?.designation ?? ""}
              </h5>
              <h5 className="business_designation">
                {cardData?.companyName ?? ""}
              </h5>
            </div>
          </div>

          <p className="mt-3 mb-4 business_des">{cardData?.briefDescription}</p>
          <div
            style={{ marginBottom: "75px", width: "100%" }}
            className="d-flex flex-column justify-content-center align-items-start mt-2"
          >
            <div className="d-flex my-2 business_card">
              <a
                className="d-flex justify-content-center align-items-center text-decoration-none"
                href={`tel:${cardData.phone}`}
              >
                <span style={iconStyle}>
                  <IoIosCall color="#4c39c6" size={30} className=" px-1 py-1" />
                </span>
                &nbsp;&nbsp;
                {cardData.phone}
              </a>
            </div>
            <div className="d-flex  my-2 business_card">
              <a
                className="d-flex justify-content-center align-items-center text-decoration-none"
                href={`https://wa.me/${cardData.whatsapp}`}
                target="_blank"
              >
                <span style={iconStyle}>
                  <AiOutlineWhatsApp
                    color="#25d366"
                    size={30}
                    className="px-1 py-1"
                  />
                </span>
                &nbsp;&nbsp;
                {cardData.whatsapp}
              </a>
            </div>
            <div className="d-flex my-2 business_card">
              <a
                className="d-flex justify-content-center align-items-center text-decoration-none"
                href={`mailto:${cardData.email}`}
              >
                <span style={iconStyle}>
                  <CgMail color="#cc3633" size={30} className="px-1 py-1" />
                </span>
                &nbsp;&nbsp;
                <p>{cardData.email}</p>
              </a>
            </div>
            {cardData.website ? (
              <div className="d-flex my-2 business_card">
                <a
                  className="d-flex justify-content-center align-items-center text-decoration-none"
                  href={cardData.website}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span style={iconStyle}>
                    <ImEarth color="#33b9a0" size={30} className="px-1 py-1" />
                  </span>
                  &nbsp;&nbsp;
                  <p>{cardData.website}</p>
                </a>
              </div>
            ) : null}

            <div className="business_social_wrapper">
              {cardData?.facebook && (
                <a
                  href={`https://www.facebook.com/${cardData?.facebook}`}
                  target="_blank"
                >
                  <RiFacebookCircleFill size={26} color="#3FAEFD" />
                </a>
              )}
              {cardData?.linkedIn && (
                <a
                  href={`https://www.linkedin.com/${cardData?.linkedIn}`}
                  target="_blank"
                >
                  <FaLinkedin size={24} color="#3FAEFD" />
                </a>
              )}
              {cardData?.instagram && (
                <a
                  href={`https://www.instagram.com/${cardData?.instagram}`}
                  target="_blank"
                >
                  <GrInstagram size={24} color="#3FAEFD" />
                </a>
              )}
            </div>

            {/* <div style={absStyle}>
              <a
                className="d-flex justify-content-center align-items-center"
                href={bucketUrl + vcfPath}
                style={{
                  fontWeight: "600",
                  color: "white",
                  fontSize: "larger",
                }}
              >
                <IoMdPersonAdd />
              </a>
            </div> */}
          </div>
          <div style={footerStyle}>
            <h5 className="business_address">{cardData.companyAddress}</h5>
          </div>
        </div>
      </LoadingOverlay>
    </PdfContainer>
  );
};

export default BussinessCard;
